.card:hover {
    color: white;
  }
  .card:hover svg {
    fill: white;
  }
  .card:hover .text {
    color: white;
  }

  