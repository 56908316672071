body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding: 5px;
  margin: 0 auto;
  max-width: 1600px;
}


@media all and (min-width: 300px) {
  main {
    padding: 10px;
  }
}

@media all and (min-width: 600px) {
  main {
    padding: 15px;
  }
}

@media all and (min-width: 1200px) {
  main {
    padding: 20px;
  }
}
