  .workflow-section {
    background-color: #770099;
    color: white;
    padding: 70px 0;
  }
  
  .workflow-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    position: relative;
    width: 200px;
    margin: 0 10px;
  }
  
  .step-item:hover {
    transform: scale(1.05);
  }
  
  .step-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .step-icon {
    background-color: white;
    color: #770099;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .step-connector {
    position: absolute;
    height: 3px;
    background-color: white;
    top: 50%;
    z-index: -1;
  }
  
  .step-text {
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  
  @media (min-width: 768px) {
    .step-item {
      width: 220px;
    }
  
    .step-icon {
      width: 60px;
      height: 60px;
      font-size: 28px;
    }
  
    .step-text {
      font-size: 20px;
    }
  }
  
