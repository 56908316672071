/* styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

*{
  margin:0;
  box-sizing: border-box;
}

.wrapper{
  width: 900px;
  height: 600px;
  position: relative;

  overflow:hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.before,
.after {
  width:100%;
  height:100%;
  background-repeat:no-repeat;
  background-color: white;
  background-size: cover;
  background-position: center;
  position: absolute;
  top:0;
  left:0;
  pointer-events:none;
  overflow: hidden;
}

.content-image{
  height:100%;
}

.after{
  width:125px;
}

.scroller{
  width: 50px;
  height:50px;
  position: absolute;
  left:100px;
  top:50%;
  transform:translateY(-50%);
  border-radius:50%;
  background-color: transparent;
  opacity:0.9;
  pointer-events:auto;
  cursor: pointer;
}

.scroller:hover{
  opacity:1;
}

.scrolling{
  pointer-events:none;
  opacity:1;
  /* // z-index: 1; */
}

.scroller__thumb{
  width:100%;
  height:100%;
  padding:5px;
}

.scroller:before,
.scroller:after{
  content:" ";
  display: block;
  width: 7px;
  height: 9999px;
  position: absolute;
  left: 50%;
  margin-left: -3.5px;
  z-index: 30;
  transition:0.1s;
}
.scroller:before{
  top:100%;
}
.scroller:after{
  bottom:100%;
}

.scroller{
  border: 5px solid #fff;
}
.scroller:before,
.scroller:after{
  background: #fff;
}












.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
