
.marquee-container {
  overflow: hidden;
}

.marquee-container {
  width: 100%; 
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-container.paused .marquee {
  animation-play-state: paused;
}

/* Brandings.css
@media (min-width: 768px) and (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
} */
