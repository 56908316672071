@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

     .clock-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;
      padding: 20px;
    }
    
    .clock-container .location {
      margin-top: 10px;
      font-size: 1.2em;
      color: #faf6f6;
    }
    
    .digital-clock {
      margin-top: 10px;
      font-size: 1.5em;
      font-weight: bold;
      color: #faf6f6;
    }
    
    @media (max-width: 600px) {
      .clock-container {
        width: 100%;
        padding: 10px;
      }
    
      .digital-clock {
        font-size: 1.2em;
      }
    
      .clock-container .location {
        font-size: 1em;
      }
    }
    