.icon {
    font-size: 45px;
    border-radius: 50%;
    padding: 5px;
    color: whitesmoke;
  }
  .card {
    position: relative;
    overflow: hidden;
  }
  /* Add these styles to your BusinessSummary.css file */

.card:hover {
  color: white;
}

.card:hover svg {
  fill: white;
}

  .triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 80px 50px; 
    border-color: transparent transparent #fff transparent; /* Adjust the background color as needed */
  }
  