
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

.talk-our-product-section {
    padding: 2.5rem;
    background-color: #7d2098;
    color: white;
  }
  
  .clocks {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .office-info {
    width: 100%;
  }
  
  .office-section {
    margin-bottom: 2rem;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .office-row {
    display: flex;
    justify-content: space-between;
  }
  
  .office-detail {
    background-color: white;
    color: black;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    width: 48%;
  }
  
  .office-location {
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  
  .office-address {
    margin-left: 1rem;
    font-size: 1rem;
  }
  
